<script>
import Layout from "../layouts/main";
import appConfig from "@/app.config";

import Stat from "@/components/widgets/widget-stat";

import { sparklineChartData, salesDonutChart, radialBarChart } from "./data";
import { statistiquesService } from '../../services/statistiques.service';
import { filesBaseUrl } from "../../constants/config";
export default {
  page: {
    title: "Tableau de bord",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    Stat,
    // Transaction,
  },
  data() {
    return {
      sparklineChartData: sparklineChartData,
      salesDonutChart: salesDonutChart,
      radialBarChart: radialBarChart,
      filesBaseUrl: filesBaseUrl,
      statDataDay: [
        {
          title: "Total album",
          image: require("@/assets/images/services-icon/01.png"),
          value: 0,
          subText: "+ 12%",
          color: "success"
        },
        {
          title: "Total morceaux",
          image: require("@/assets/images/services-icon/02.png"),
          value: 0,
          subText: "- 28%",
          color: "danger"
        },
        {
          title: "Revenu total album",
          image: require("@/assets/images/services-icon/03.png"),
          value: 0,
          isRevenu: true,
          subText: "00%",
          color: "info"
        },
        {
          title: "Revenu total morceaux",
          image: require("@/assets/images/services-icon/04.png"),
          value: 0,
          isRevenu: true,
          subText: "+ 84%",
          color: "warning"
        }
      ],
      statDataMonth: [
        {
          title: "Total album",
          image: require("@/assets/images/services-icon/01.png"),
          value: 0,
          subText: "+ 12%",
          color: "success"
        },
        {
          title: "Total morceaux",
          image: require("@/assets/images/services-icon/02.png"),
          value: 0,
          subText: "- 28%",
          color: "danger"
        },
        {
          title: "Revenu total album",
          image: require("@/assets/images/services-icon/03.png"),
          value: 0,
          isRevenu: true,
          subText: "00%",
          color: "info"
        },
        {
          title: "Revenu total morceaux",
          image: require("@/assets/images/services-icon/04.png"),
          value: 0,
          isRevenu: true,
          subText: "+ 84%",
          color: "warning"
        }
      ],
      statDataWeek: [
        {
          title: "Total album",
          image: require("@/assets/images/services-icon/01.png"),
          value: 0,
          subText: "+ 12%",
          color: "success"
        },
        {
          title: "Total morceaux",
          image: require("@/assets/images/services-icon/02.png"),
          value: 0,
          subText: "- 28%",
          color: "danger"
        },
        {
          title: "Revenu total album",
          image: require("@/assets/images/services-icon/03.png"),
          value: 0,
          isRevenu: true,
          subText: "00%",
          color: "info"
        },
        {
          title: "Revenu total morceaux",
          image: require("@/assets/images/services-icon/04.png"),
          value: 0,
          isRevenu: true,
          subText: "+ 84%",
          color: "warning"
        }
      ],
      
      ecoutesDataDay: [
        {
          title: "Total",
          image: require("@/assets/images/services-icon/01.png"),
          value: 0,
          subText: "+ 12%",
          color: "success"
        }
      ],
      ecoutesDataWeek: [
        {
          title: "Total",
          image: require("@/assets/images/services-icon/01.png"),
          value: 0,
          subText: "+ 12%",
          color: "success"
        }
      ],
      ecoutesDataMonth: [
        {
          title: "Total",
          image: require("@/assets/images/services-icon/01.png"),
          value: 0,
          subText: "+ 12%",
          color: "success"
        }
      ],
      albums: {
        downloadDay: null,
        downloadWeek: null,
        downloadMonth: null,
        downloadAll: null
      },
      morceaux: {
        ecoutesDay: null,
        ecoutesWeek: null,
        ecoutesMonth: null,
        ecoutesAll: null,
        downloadDay: null,
        downloadWeek: null,
        downloadMonth: null,
        downloadAll: null
      },
      transactions: [
        {
          id: "#14256",
          image: require("@/assets/images/users/user-2.jpg"),
          name: "Philip Smead",
          date: "0/1/2018",
          amount: "$94",
          status: "Delivered"
        },
        {
          id: "#14257",
          image: require("@/assets/images/users/user-3.jpg"),
          name: "Brent Shipley",
          date: "16/1/2019",
          amount: "$112",
          status: "Pending"
        },
        {
          id: "#14258",
          image: require("@/assets/images/users/user-4.jpg"),
          name: "Robert Sitton",
          date: "17/1/2019",
          amount: "$116",
          status: "Delivered"
        },
        {
          id: "#14259",
          image: require("@/assets/images/users/user-5.jpg"),
          name: "Alberto Jackson",
          date: "18/1/2019",
          amount: "$109",
          status: "Cancel"
        },
        {
          id: "#14260",
          image: require("@/assets/images/users/user-6.jpg"),
          name: "David Sanchez",
          date: "0/1/2018",
          amount: "$94",
          status: "Delivered"
        },
        {
          id: "#14261",
          image: require("@/assets/images/users/user-2.jpg"),
          name: "Philip Smead",
          date: "0/1/2018",
          amount: "$94",
          status: "Pending"
        }
      ],
      chatMessages: [
        {
          id: 1,
          image: require("@/assets/images/users/user-2.jpg"),
          name: "John Deo",
          message: "Hello!",
          time: "10:00"
        },
        {
          id: 2,
          image: require("@/assets/images/users/user-3.jpg"),
          name: "Smith",
          message: "Hi, How are you? What about our next meeting?",
          time: "10:01",
          odd: true
        },
        {
          id: 3,
          image: require("@/assets/images/users/user-2.jpg"),
          name: "John Deo",
          message: "Yeah everything is fine",
          time: "10:01"
        },
        {
          id: 4,
          image: require("@/assets/images/users/user-3.jpg"),
          name: "Smith",
          message: "Wow that's great",
          time: "10:02",
          odd: true
        },
        {
          id: 5,
          image: require("@/assets/images/users/user-2.jpg"),
          name: "John Deo",
          message: "Yup!",
          time: "10:03"
        }
      ],
      activityData: [
        {
          date: "Jan 22",
          text: "Responded to need “Volunteer Activities”"
        },
        {
          date: "Jan 20",
          text:
            "At vero eos et accusamus et iusto odio dignissimos ducimus qui deleniti atque... "
        },
        {
          date: "Jan 19",
          text: "Joined the group “Boardsmanship Forum”"
        },
        {
          date: "Jan 17",
          text: "Responded to need “In-Kind Opportunity”"
        },
        {
          date: "Jan 16",
          text: "Sed ut perspiciatis unde omnis iste natus error sit rem."
        }
      ],
      stat: null
    };
  },
  mounted() {
    this.getStatistique();
  },
  methods: {
    async getStatistique() {
      this.stat = await statistiquesService.get();
      let download = this.stat.download;
      let ecoutes = this.stat.ecoutes;
      let today = {
        totalAlbum: 0,
        totalmorceaux: 0,
        revenuTotalMorceaux: 0,
        revenuTotalAlbum: 0,
        ecouteTotal: 0
      };
      let thisWeek = {
        totalAlbum: 0,
        totalmorceaux: 0,
        revenuTotalMorceaux: 0,
        revenuTotalAlbum: 0,
        ecouteTotal: 0
      };
      let thisMonth = {
        totalAlbum: 0,
        totalmorceaux: 0,
        revenuTotalMorceaux: 0,
        revenuTotalAlbum: 0,
        ecouteTotal: 0
      };
      this.albums.downloadAll = download.album.sort((a, b) => a.count < b.count)[0];
      this.albums.downloadDay = download.album.sort((a, b) => a.countThisDay < b.countThisDay)[0];
      this.albums.downloadWeek = download.album.sort((a, b) => a.countThisWeek < b.countThisWeek)[0];
      this.albums.downloadMonth = download.album.sort((a, b) => a.countThisMonth < b.countThisMonth)[0];
      this.morceaux.downloadAll = download.morceaux.sort((a, b) => a.count < b.count)[0];
      this.morceaux.downloadDay = download.morceaux.sort((a, b) => a.countThisDay < b.countThisDay)[0];
      this.morceaux.downloadWeek = download.morceaux.sort((a, b) => a.countThisWeek < b.countThisWeek)[0];
      this.morceaux.downloadMonth = download.morceaux.sort((a, b) => a.countThisMonth < b.countThisMonth)[0];

      download.album.forEach(element => {
        today.totalAlbum += element.countThisDay;
        today.revenuTotalAlbum += parseInt(element.countThisDay) * parseInt(element.album.amount);

        thisWeek.totalAlbum += element.countThisWeek;
        thisWeek.revenuTotalAlbum += parseInt(element.countThisWeek) * parseInt(element.album.amount);

        thisMonth.totalAlbum += element.countThisMonth;
        thisMonth.revenuTotalAlbum += parseInt(element.countThisMonth) * parseInt(element.album.amount);
      });

      
      download.morceaux.forEach(element => {
        today.totalmorceaux += element.countThisDay;
        today.revenuTotalMorceaux += parseInt(element.countThisDay) * parseInt(element.morceaux.amount);
        
        thisWeek.totalmorceaux += element.countThisWeek;
        thisWeek.revenuTotalMorceaux += parseInt(element.countThisWeek) * parseInt(element.morceaux.amount);
        
        thisMonth.totalmorceaux += element.countThisMonth;
        thisMonth.revenuTotalMorceaux += parseInt(element.countThisMonth) * parseInt(element.morceaux.amount);
      });

      this.morceaux.ecoutesAll = ecoutes.morceaux.sort((a, b) => a.count < b.count)[0];
      this.morceaux.ecoutesDay = ecoutes.morceaux.sort((a, b) => a.countThisDay < b.countThisDay)[0];
      this.morceaux.ecoutesWeek = ecoutes.morceaux.sort((a, b) => a.countThisWeek < b.countThisWeek)[0];
      this.morceaux.ecoutesMonth = ecoutes.morceaux.sort((a, b) => a.countThisMonth < b.countThisMonth)[0];
      
      ecoutes.morceaux.forEach(element => {
        today.ecouteTotal += element.countThisDay;
        thisWeek.ecouteTotal += element.countThisWeek;
        thisMonth.ecouteTotal += element.countThisMonth;
      });

      this.statDataDay[0].value = today.totalAlbum;
      this.statDataDay[1].value = today.totalmorceaux;
      this.statDataDay[2].value = today.revenuTotalAlbum;
      this.statDataDay[3].value = today.revenuTotalMorceaux;

      this.statDataMonth[0].value = thisMonth.totalAlbum;
      this.statDataMonth[1].value = thisMonth.totalmorceaux;
      this.statDataMonth[2].value = thisMonth.revenuTotalAlbum;
      this.statDataMonth[3].value = thisMonth.revenuTotalMorceaux;

      this.statDataWeek[0].value = thisWeek.totalAlbum;
      this.statDataWeek[1].value = thisWeek.totalmorceaux;
      this.statDataWeek[2].value = thisWeek.revenuTotalAlbum;
      this.statDataWeek[3].value = thisWeek.revenuTotalMorceaux;

      this.ecoutesDataDay[0].value = today.ecouteTotal;
      this.ecoutesDataWeek[0].value = thisWeek.ecouteTotal;
      this.ecoutesDataMonth[0].value = thisMonth.ecouteTotal;
    },
  }
};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">Tableau de bord</h4>
          <ol class="breadcrumb mb-0">
            <!-- <li class="breadcrumb-item active">Welcome to Veltrix Dashboard</li> -->
          </ol>
        </div>
      </div>

    </div>
    <!-- end page title -->
    <b-card v-if="stat != null">
      
        <h3>Téléchargements</h3>
      
      
        <div class="row">
          <div class="col-md-12">
            <h5>Aujourd'hui</h5>
          </div>

          <div class="col-xl-3 col-md-6" v-for="stat of statDataDay" :key="stat.icon">
            <Stat :title="stat.title" :image="stat.image" :subText="stat.subText" :value="stat.value" :isRevenu="stat.isRevenu" :color="stat.color" />
          </div>

          <div class="col-md-12">
            <h5>Cette semaine</h5>
          </div>

          <div class="col-xl-3 col-md-6" v-for="stat of statDataWeek" :key="stat.icon">
            <Stat :title="stat.title" :image="stat.image" :subText="stat.subText" :value="stat.value" :isRevenu="stat.isRevenu" :color="stat.color" />
          </div>

          <div class="col-md-12">
            <h5>Ce mois</h5>
          </div>

          <div class="col-xl-3 col-md-6" v-for="stat of statDataMonth" :key="stat.icon">
            <Stat :title="stat.title" :image="stat.image" :subText="stat.subText" :value="stat.value" :isRevenu="stat.isRevenu" :color="stat.color" />
          </div>
      </div>
      
    </b-card>
    <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <div class="col-md-3">
              <div class="card text-center">
                <div class="card-body">
                  <div v-if="albums.downloadDay != null && albums.downloadDay.countThisDay != 0" class="text-center py-4">
                      <h5 class="mt-0 mb-4 font-size-16">Album / Aujourd'hui</h5>
                      <img :src="filesBaseUrl + '/album/' + albums.downloadDay.album.image" width="50" alt="">
                      <h2>{{ albums.downloadDay.album.libelle }}</h2>
                      <p class="font-size-17 pt-1">{{ albums.downloadDay.countThisDay }} <i class="fa fa-download"></i></p>
                  </div>
                  <div v-if="albums.downloadDay == null || (albums.downloadDay != null && albums.downloadDay.countThisDay == 0)" class="text-center py-4">
                    <h5 class="mt-0 mb-4 font-size-16">Album / Aujourd'hui</h5>
                      Aucun album téléchargé
                  </div>
                </div>
              </div>
            </div>
            
            <div class="col-md-3">
              <div class="card text-center">
                <div class="card-body">
                  <div v-if="albums.downloadWeek != null && albums.downloadWeek.countThisWeek != 0" class="text-center py-4">
                      <h5 class="mt-0 mb-4 font-size-16">Album / cette semaine</h5>
                      <img :src="filesBaseUrl + '/album/' + albums.downloadWeek.album.image" width="50" alt="">
                      <h2>{{ albums.downloadWeek.album.libelle }}</h2>
                      <p class="font-size-17 pt-1">{{ albums.downloadWeek.countThisWeek }} <i class="fa fa-download"></i></p>
                  </div>
                  <div v-if="albums.downloadWeek == null || (albums.downloadWeek != null && albums.downloadWeek.countThisWeek == 0)" class="text-center py-4">
                      <h5 class="mt-0 mb-4 font-size-16">Album / cette semaine</h5>
                      Aucun album téléchargé
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card text-center">
                <div class="card-body">
                  <div v-if="albums.downloadMonth != null && albums.downloadWeek.countThisMonth != 0" class="text-center py-4">
                      <h5 class="mt-0 mb-4 font-size-16">Album / ce mois</h5>
                      <img :src="filesBaseUrl + '/album/' + albums.downloadMonth.album.image" width="50" alt="">
                      <h2>{{ albums.downloadMonth.album.libelle }}</h2>
                      <p class="font-size-17 pt-1">{{ albums.downloadMonth.countThisMonth }} <i class="fa fa-download"></i></p>
                  </div>
                  <div v-if="albums.downloadMonth == null || (albums.downloadMonth != null && albums.downloadMonth.countThisMonth == 0)" class="text-center py-4">
                      <h5 class="mt-0 mb-4 font-size-16">Album / ce mois</h5>
                      Aucun album téléchargé
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card bg-primary">
                <div class="card-body">
                  <div v-if="albums.downloadAll != null" class="text-center text-white py-4">
                    <h5 class="mt-0 mb-4 text-white text-bold font-size-16">Album le plus téléchargé</h5>
                    <img :src="filesBaseUrl+'/album/'+albums.downloadAll.album.image" width="50" alt="">
                    <h2>{{ albums.downloadAll.album.libelle }}</h2>
                    <p class="font-size-17 pt-1">{{ albums.downloadAll.count }} <i class="fa fa-download"></i></p>
                    
                  </div>
                  <div v-if="albums.downloadAll == null || (albums.downloadAll != null && albums.downloadAll.count == 0)" class="text-center py-4">
                    <h5 class="mt-0 mb-4 text-white text-bold font-size-16">Album le plus téléchargé</h5>
                      Aucun album téléchargé
                  </div>
                </div>
              </div>
            </div>
          </div>
      
        </div>
    </div>

    <!-- Morceaux télécharger -->
     <div class="row">
          <div class="col-xl-12">
            <div class="row">
              <div class="col-md-3">
                <div class="card text-center">
                  <div class="card-body">
                    <div v-if="morceaux.downloadDay != null && morceaux.downloadDay.countThisDay != 0" class="text-center py-4">
                        <h5 class="mt-0 mb-4 font-size-16">Morceaux / Aujourd'hui</h5>
                        <img :src="filesBaseUrl + '/morceaux/' + morceaux.downloadDay.morceaux.image" width="50" alt="">
                        <h2>{{ morceaux.downloadDay.morceaux.libelle }}</h2>
                        <p class="font-size-17 pt-1">{{ morceaux.downloadDay.countThisDay }} <i class="fa fa-download"></i></p>
                    </div>
                    <div v-if="morceaux.downloadDay == null || (morceaux.downloadDay != null && morceaux.downloadDay.countThisDay == 0)" class="text-center py-4">
                      <h5 class="mt-0 mb-4 font-size-16">Morceaux / Aujourd'hui</h5>
                        Aucun morceaux téléchargé
                    </div>
                  </div>
                </div>
              </div>
            
              <div class="col-md-3">
                <div class="card text-center">
                  <div class="card-body">
                    <div v-if="morceaux.downloadWeek != null && morceaux.downloadWeek.countThisWeek != 0" class="text-center py-4">
                        <h5 class="mt-0 mb-4 font-size-16">Morceaux / cette semaine</h5>
                        <img :src="filesBaseUrl + '/morceaux/' + morceaux.downloadWeek.morceaux.image" width="50" alt="">
                        <h2>{{ morceaux.downloadWeek.morceaux.libelle }}</h2>
                        <p class="font-size-17 pt-1">{{ morceaux.downloadWeek.countThisWeek }} <i class="fa fa-download"></i></p>
                    </div>
                    <div v-if="morceaux.downloadWeek == null || (morceaux.downloadWeek != null && morceaux.downloadWeek.countThisWeek == 0)" class="text-center py-4">
                        <h5 class="mt-0 mb-4 font-size-16">Morceaux / cette semaine</h5>
                        Aucun morceaux téléchargé
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card text-center">
                  <div class="card-body">
                    <div v-if="morceaux.downloadMonth != null && morceaux.downloadWeek.countThisMonth != 0" class="text-center py-4">
                        <h5 class="mt-0 mb-4 font-size-16">Morceaux / ce mois</h5>
                        <img :src="filesBaseUrl + '/morceaux/' + morceaux.downloadMonth.morceaux.image" width="50" alt="">
                        <h2>{{ morceaux.downloadMonth.morceaux.libelle }}</h2>
                        <p class="font-size-17 pt-1">{{ morceaux.downloadMonth.countThisMonth }} <i class="fa fa-download"></i></p>
                    </div>
                    <div v-if="morceaux.downloadMonth == null || (morceaux.downloadMonth != null && morceaux.downloadMonth.countThisMonth == 0)" class="text-center py-4">
                        <h5 class="mt-0 mb-4 font-size-16">Morceaux / ce mois</h5>
                        Aucun morceaux téléchargé
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card bg-primary">
                  <div class="card-body">
                    <div v-if="morceaux.downloadAll != null" class="text-center text-white py-4">
                      <h5 class="mt-0 mb-4 text-white text-bold font-size-16">Morceaux le plus téléchargé</h5>
                      <img :src="filesBaseUrl + '/morceaux/' + morceaux.downloadAll.morceaux.image" width="50" alt="">
                      <h2>{{ morceaux.downloadAll.morceaux.libelle }}</h2>
                      <p class="font-size-17 pt-1">{{ morceaux.downloadAll.count }} <i class="fa fa-download"></i></p>
                    
                    </div>
                    <div v-if="morceaux.downloadAll == null || (morceaux.downloadAll != null && morceaux.downloadAll.count == 0)" class="text-center py-4">
                      <h5 class="mt-0 mb-4 text-white text-bold font-size-16">Morceaux le plus téléchargé</h5>
                        Aucun morceaux téléchargé
                    </div>
                  </div>
                </div>
              </div>
            </div>
      
          </div>
      </div>

    <b-card>
      
        <h3>Ecoutes</h3>
      
      
        <div class="row">
          <div class="col-md-4">
            <h5>Aujourd'hui</h5>
             <div v-for="stat of ecoutesDataDay" :key="stat.icon">
              <Stat :title="stat.title" :image="stat.image" :subText="stat.subText" :value="stat.value" :color="stat.color" />
            </div>
          </div>
          <div class="col-md-4">
            <h5>Cette semaine</h5>
              <div v-for="stat of ecoutesDataWeek" :key="stat.icon">
              <Stat :title="stat.title" :image="stat.image" :subText="stat.subText" :value="stat.value" :color="stat.color" />
            </div>
          </div>
          <div class="col-md-4">
            <h5>Ce mois</h5>
              <div v-for="stat of ecoutesDataMonth" :key="stat.icon">
              <Stat :title="stat.title" :image="stat.image" :subText="stat.subText" :value="stat.value" :color="stat.color" />
            </div>
          </div>
      </div>
      
    </b-card>
    <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <div class="col-md-3">
              <div class="card text-center">
                <div class="card-body">
                  <div v-if="morceaux.ecoutesDay != null && morceaux.ecoutesDay.countThisDay != 0" class="text-center py-4">
                      <h5 class="mt-0 mb-4 font-size-16">Morceaux / Aujourd'hui</h5>
                      <img :src="filesBaseUrl + '/morceaux/' + morceaux.ecoutesDay.morceaux.image" width="50" alt="">
                      <h2>{{ morceaux.ecoutesDay.morceaux.libelle }}</h2>
                      <p class="font-size-17 pt-1">{{ morceaux.ecoutesDay.countThisDay }} <i class="fa fa-headphones"></i></p>
                  </div>
                  <div v-if="morceaux.ecoutesDay == null || (morceaux.ecoutesDay != null && morceaux.ecoutesDay.countThisDay == 0)" class="text-center py-4">
                    <h5 class="mt-0 mb-4 font-size-16">Morceaux / Aujourd'hui</h5>
                      Aucun morceaux écouté
                  </div>
                </div>
              </div>
            </div>
            
            <div class="col-md-3">
              <div class="card text-center">
                <div class="card-body">
                  <div v-if="morceaux.ecoutesWeek != null && morceaux.ecoutesWeek.countThisWeek != 0" class="text-center py-4">
                      <h5 class="mt-0 mb-4 font-size-16">Morceaux / cette semaine</h5>
                      <img :src="filesBaseUrl + '/morceaux/' + morceaux.ecoutesWeek.morceaux.image" width="50" alt="">
                      <h2>{{ morceaux.ecoutesWeek.morceaux.libelle }}</h2>
                      <p class="font-size-17 pt-1">{{ morceaux.ecoutesWeek.countThisWeek }} <i class="fa fa-headphones"></i></p>
                  </div>
                  <div v-if="morceaux.ecoutesWeek == null || (morceaux.ecoutesWeek != null && morceaux.ecoutesWeek.countThisWeek == 0)" class="text-center py-4">
                      <h5 class="mt-0 mb-4 font-size-16">Morceaux / cette semaine</h5>
                      Aucun morceaux écouté
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card text-center">
                <div class="card-body">
                  <div v-if="morceaux.ecoutesMonth != null && morceaux.ecoutesWeek.countThisMonth != 0" class="text-center py-4">
                      <h5 class="mt-0 mb-4 font-size-16">Morceaux / ce mois</h5>
                      <img :src="filesBaseUrl + '/morceaux/' + morceaux.ecoutesMonth.morceaux.image" width="50" alt="">
                      <h2>{{ morceaux.ecoutesMonth.morceaux.libelle }}</h2>
                      <p class="font-size-17 pt-1">{{ morceaux.ecoutesMonth.countThisMonth }} <i class="fa fa-headphones"></i></p>
                  </div>
                  <div v-if="morceaux.ecoutesMonth == null || (morceaux.ecoutesMonth != null && morceaux.ecoutesMonth.countThisMonth == 0)" class="text-center py-4">
                      <h5 class="mt-0 mb-4 font-size-16">Morceaux / ce mois</h5>
                      Aucun morceaux écouté
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card bg-primary">
                <div class="card-body">
                  <div v-if="morceaux.ecoutesAll != null" class="text-center text-white py-4">
                    <h5 class="mt-0 mb-4 text-white text-bold font-size-16">Morceaux le plus écouté</h5>
                    <img :src="filesBaseUrl+'/morceaux/'+morceaux.ecoutesAll.morceaux.image" width="50" alt="">
                    <h2>{{ morceaux.ecoutesAll.morceaux.libelle }}</h2>
                    <p class="font-size-17 pt-1">{{ morceaux.ecoutesAll.count }} <i class="fa fa-headphones"></i></p>
                    
                  </div>
                  <div v-if="morceaux.ecoutesAll == null || (morceaux.ecoutesAll != null && morceaux.ecoutesAll.count == 0)" class="text-center py-4">
                    <h5 class="mt-0 mb-4 text-white text-bold font-size-16">Morceaux le plus écouté</h5>
                      Aucun morceaux écouté
                  </div>
                </div>
              </div>
            </div>
          </div>
      
        </div>
    </div>

    <!-- <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Transaction</h4>
            <Transaction :transactions="transactions" />
          </div>
        </div>
      </div>
    </div> -->
    <!-- end row -->
  </Layout>
</template>